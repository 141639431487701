import React, { SVGAttributes } from "react"

import { COLORS } from "src/constants"

const FurnitureIcon: React.FC<SVGAttributes<SVGElement>> = (props) => (
  <svg viewBox="0 0 260.6 260.6" fill={COLORS.main} {...props}>
    <path
      d="M76.2,187.8h-4.8c-4.8,0-8.8-3.9-8.8-8.7l-0.2-8.7c-0.3-0.1-0.6-0.1-0.9-0.2c-9.5-1.9-24.6-9.2-24.3-20.4
			c0.6-22,1.5-41.7,2.1-56.5c0.5-10.1,8.4-18.4,18.9-19.8c2.4-0.2,4.1-0.3,5.6-0.3h67.5c11,0,21.9,0,32.9,0s21.9,0,32.9,0
			c4.4,0,14.4,0,20.3,9c2.6,4,4,7.9,4.2,11.6c0.3,5.8,0.5,11.8,0.7,17.6l0.2,6.3c0.3,9.5,0.7,19.3,1.3,28.8
			c0.5,7.4-1.6,13.7-6.1,18.2c-3.8,3.9-13.8,5.4-19.2,5.9l0.2,8.2c0,4.9-3.9,8.8-8.8,8.8l-5.1,0.1l0,0c-4.9,0-8.9-3.9-8.9-8.7
			l-0.2-7.9H84.8L85,179C85,183.8,81,187.8,76.2,187.8z M63.8,80.6c-1.3,0-2.8,0.1-4.8,0.3c-6.7,0.9-12,6.3-12.3,12.8
			c-0.6,14.7-1.5,34.5-2.1,56.4c-0.1,5.2,9.6,11.1,18.4,12.9c1.2,0.3,2.3,0.4,3.4,0.5l3.2,0.4l0.2,3.2l0.3,11.9
			c0,0.7,0.5,1.4,1.3,1.4h4.8c0.8,0,1.4-0.7,1.4-1.3l-0.4-15.4H183l0.4,15.2c0,0.8,0.7,1.4,1.4,1.4l5.1-0.1c0.8,0,1.5-0.6,1.5-1.3
			l-0.4-15.3l3.7-0.1c7-0.3,16.2-2.3,17.9-4c2.9-2.9,4.2-7.3,3.9-12.5c-0.6-9.6-1-19.4-1.3-29l-0.2-6.3c-0.2-5.8-0.4-11.7-0.7-17.5
      c-0.1-2.3-1.1-5-3-7.9c-3.4-5.2-8.9-5.6-13.8-5.6c-11.2,0-22.1,0-33.1,0c-11,0-21.9,0-32.9,0L63.8,80.6L63.8,80.6z"
    />
    <path
      d="M130.5,171.1L130.5,171.1c-2.1,0-3.7-1.7-3.7-3.7l0.8-90.5c0-2.1,1.8-3.7,3.7-3.7c2.1,0,3.7,1.7,3.7,3.7
	l-0.8,90.5C134.2,169.5,132.5,171.1,130.5,171.1z"
    />
    <circle cx="148.6" cy="122.4" r="6.2" />
    <circle cx="112.2" cy="122.4" r="6.2" />
  </svg>
)

export default FurnitureIcon
