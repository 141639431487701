import React, { useRef } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import { GatsbyPage, IShowcase } from "src/types"
import Layout from "src/layout"
import Image from "src/components/Image"
import { SIZES, COLORS, EMAIL_ADDRESS } from "src/constants"
import {
  Section,
  Subtitle,
  Title,
  Description,
  SectionTitle,
  Text,
  Features,
  Feature,
  FeatCaption,
  BookSection,
  Book,
  BookTitle,
  BookSubtitle,
  InfosContainer,
  Infos,
  InfosImage,
  InfosContent,
  InfosTitle,
  InfosSubtitle,
  InfosText,
  Content,
  ImageCaption,
  ScrollCta,
} from "src/components/styled"
import DecoIcon from "src/svg/icon/Deco"
import LampIcon from "src/svg/icon/Lamp"
import FurnitureIcon from "src/svg/icon/Furniture"
import BuilderIcon from "src/svg/icon/Builder"
import { Splash, Parallax } from "src/components/Parallax"
import { scrollToRef, smoothScroll } from "src/helpers/window"

const Label = styled.p`
  display: block;
  font-weight: bold;
  font-size: ${SIZES.normal};
  span {
    color: ${COLORS.main};
  }
  a {
    text-decoration: underline;
  }
`

const EventsPage: GatsbyPage = ({ data }) => {
  const quote = useRef<HTMLDivElement>()

  const handleScroll = (): void => {
    smoothScroll(quote.current, quote.current.offsetHeight / 2 - window.innerHeight / 2, 1000)
  }

  return (
    <Layout title="Évènements, groupes et entreprises">
      <Splash>
        <Parallax desktop={data.splashDesktop.childImageSharp} mobile={data.splashMobile.childImageSharp} />
      </Splash>
      <Content>
        <Section>
          <Subtitle>GROUPES ET ENTREPRISES</Subtitle>
          <Title ref={scrollToRef}>
            <span>évènements, entreprises, groupes… construire ensemble vos projets</span>
          </Title>
          <Features>
            <Feature>
              <DecoIcon />
              <FeatCaption>atelier démo en accès libre</FeatCaption>
            </Feature>
            <Feature>
              <LampIcon />
              <FeatCaption>atelier petit objet en 1 à 2 h</FeatCaption>
            </Feature>
            <Feature>
              <FurnitureIcon />
              <FeatCaption>construction collaborative à partir de 3 h</FeatCaption>
            </Feature>
          </Features>
          <Description>
            L’atelier sur-mesure s’adapte à vos besoins : Evènement, anniversaire, atelier entre amis… Il peut se faire
            dans notre atelier du Viaduc des Arts ou chez vous grâce à notre atelier mobile.
            <br />
            <br />
            <u>Entreprise !</u>
            <br />
            Que ce soit pour l’organisation d’un atelier en équipe, de l’aménagement d’une partie de votre espace de
            travail en co-construction avec les salariés, ou pour proposer des formations, nous nous adaptons aux
            besoins et contraintes de votre entreprise. De même pour les mobiliers réalisés, vous avez le choix de les
            fabriquer à partir de modèles que nous avons dessinés pour vous, ou bien concevoir et personnaliser
            vous-même vos objets.
          </Description>
          <ScrollCta onClick={handleScroll}>
            <span>
              Demander un
              <br />
              devis pour
              <br />
              un projet
            </span>
            <BuilderIcon />
          </ScrollCta>
          <SectionTitle>Quelques idées pour inspirer votre atelier sur-mesure…</SectionTitle>
          <Text>
            Venez avec votre équipe ou vos amis passer un moment convivial à l’atelier tout en construisant ensemble.
            C’est l’occasion d’être également sensibilisé à l’éco-conception directement par l’action !
          </Text>
          <Text>Quelques exemples :</Text>
          {data.showcase.nodes.map(({ id, photo, description }: IShowcase) => (
            <figure key={id}>
              <Image file={photo} />
              <ImageCaption>{description}</ImageCaption>
            </figure>
          ))}
        </Section>
        <BookSection ref={quote}>
          <BuilderIcon />
          <Book>
            <BookTitle>Demander un devis</BookTitle>
            <BookSubtitle>évènement, entreprise, aménagement…</BookSubtitle>
            <Label>
              Contactez-nous par mail à <a href={`mailto:${EMAIL_ADDRESS}`}>{EMAIL_ADDRESS}</a>
            </Label>
            <Label>
              <span>N’oubliez pas de préciser votre demande :</span> date, nombre de participant·e·s, temps, envies,
              projet...
              <br />
              N’hésitez pas à laisser votre numéro pour que nous puissions en discuter par téléphone.
            </Label>
          </Book>
        </BookSection>
        <InfosContainer>
          <Infos>
            <InfosImage file={data.infos.childImageSharp} alt="" />
            <InfosContent>
              <InfosTitle>Déroulement de l’atelier</InfosTitle>
              <InfosSubtitle>Bien préparer son atelier :</InfosSubtitle>
              <InfosText>
                Choisissez une tenue adaptée : pas de vêtements amples, attachez vos cheveux, retirez vos bijoux, ayez
                des chaussures fermées (même si c’est l’été).
              </InfosText>
              <InfosSubtitle>Pendant l’atelier :</InfosSubtitle>
              <InfosText>
                1. Le formateur vous proposera de choisir parmi plusieurs modèles d’objets (lampe, patère, étagère, etc)
                celui que vous souhaitez réaliser.
                <br />
                2. Pour votre réalisation, vous aurez le choix parmi les matériaux disponibles le jour de votre atelier.
                N’oubliez pas qu’il s’agit de valoriser du bois, il faudra donc parfois faire preuve de créativité pour
                vous adapter à la matière disponible.
                <br />
                3. Guidé par le formateur, vous utiliserez avec lui les machines et outils pour réaliser votre création
                et repartir avec à l’issue de l’atelier.
              </InfosText>
              <InfosSubtitle>Après l’atelier :</InfosSubtitle>
              <InfosText>
                Anticipez le retour chez vous, surtout si votre meuble est encombrant, car nous n’avons pas la place
                pour stocker les objets réalisés.
                <br />
                De retour chez vous, n’hésitez pas à vernir, peindre ou huiler votre mobilier pour le protéger des
                projections et le conserver en bon état plus longtemps.
              </InfosText>
            </InfosContent>
          </Infos>
        </InfosContainer>
      </Content>
    </Layout>
  )
}

export default EventsPage

export const query = graphql`
  query {
    splashDesktop: file(relativePath: { eq: "events/splash-desktop.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    splashMobile: file(relativePath: { eq: "events/splash-mobile.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    showcase: allContentfulShowcase(sort: { fields: [position], order: ASC }) {
      nodes {
        id: contentful_id
        photo {
          fluid(maxWidth: 1160) {
            ...GatsbyContentfulFluid
          }
        }
        description
      }
    }
    infos: file(relativePath: { eq: "infos.png" }) {
      childImageSharp {
        fluid(maxWidth: 200, traceSVG: { background: "#E10F14", color: "#FFFFFF" }) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`
